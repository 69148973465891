<template>
    <div>
        <ul class="leadscore" v-for="item in solicitud.leadscore">
            <li :class="clase(item.calculo_semaforo)"><span class="subtitle">Opinión de valor:</span> {{ item.calculo_semaforo }}%</li>
            <li :class="clase(item.calculo_semaforo)"><span class="subtitle">Preautorización:</span> {{ item.calculo_semaforo }}%</li>
            <li :class="clase(item.calculo_semaforo)"><span class="subtitle">Documentación:</span> {{ item.calculo_semaforo }}%</li>
            <li :class="clase(item.calculo_semaforo)"><span class="subtitle">Autorización:</span> {{ item.calculo_semaforo }}%</li>
            <li :class="clase(item.calculo_semaforo)"><span class="subtitle">Ejercido:</span> {{ item.calculo_semaforo }}%</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        solicitud: {
            type: Object,
            default() {
                return {
                    leadscore: []
                }
            }
        }
    }
    ,methods: {
        clase(calculo) {
            let clase = 'bg_danger';

            if (calculo > 0.30 && calculo <= 0.60)
                clase = 'bg_warning';
            else if (calculo > 0.60)
                clase = 'bg_success'

            return clase;
        }
    }
}
</script>

<style lang="scss">
    ul.leadscore {
        width: 450px;
        list-style: none;
        padding: 0px;
        margin: 10px 0px;

        li {
            padding: 5px 10px;

            span.subtitle {
                width: 240px;
                display: inline-block;
            }
        }
    }
    .bg_danger {
        background-color: #f22b09;
        color: #fff;

        span.subtitle {
            color: #fff;
        }
    }

    .bg_warning {
        background-color: #d0e31f;
    }

    .bg_success {
        background-color: #66ce22;
    }
</style>