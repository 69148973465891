<template>
    <div>
        <h3>Nombre: {{ solicitud.nombre }}</h3>
        <ul>
            <li><span class="subtitle">Teléfono:</span> {{ solicitud.telefono }}</li>
            <li><span class="subtitle">Celular:</span> {{ solicitud.celular }}</li>
            <li><span class="subtitle">E-Mail:</span> {{ solicitud.email }}</li>
            <li><span class="subtitle">Método de contacto preferido:</span> {{ solicitud.metodo_preferido_contacto }}</li>
            <li><span class="subtitle">Medio de información:</span> {{ solicitud.medio_informacion.nombre }}</li>
            <li><span class="subtitle">Acepta políticas de privacidad:</span> {{ solicitud.acepta_politicas_privacidad == 1 ? 'Sí' : 'No' }}</li>
        </ul>
        <hr>
        <ul>
            <li><span class="subtitle">Ingreso mensual:</span> ${{ $helper.moneyFormat(solicitud.ingreso_mensual) }}</li>
            <li><span class="subtitle">Monto solicitado:</span> ${{ $helper.moneyFormat(solicitud.importe_solicitado) }}</li>
            <li><span class="subtitle">Monto pre-autorizado:</span> ${{ $helper.moneyFormat(solicitud.importe_pre_autorizado) }}</li>
            <li><span class="subtitle">Aceptó el importe pre-autorizado:</span> {{ solicitud.acepto_importe_pre_autorizado == 1 ? 'Sí' : 'No' }}</li>
            <li><span class="subtitle">Plazo:</span> {{ solicitud.plazo }}</li>
            <li><span class="subtitle">Descripción:</span> {{ solicitud.descripcion_solicitud }}</li>
            <li><span class="subtitle">Referente:</span> {{ solicitud.referente }}</li>
        </ul>
        <hr>
        <ul>
            <li><span class="subtitle">Buró Score:</span> {{ solicitud.scoring && solicitud.scoring.buro.length > 0 ? solicitud.scoring.buro[0].score : "N/A" }}</li>
            <li><span class="subtitle">Avatar Score:</span> {{ solicitud.scoring && solicitud.scoring.avatar ? solicitud.scoring.avatar.riesgo_persona : "N/A" }}</li>
            <li><span class="subtitle">OCR Ingresos:</span> {{ solicitud.scoring && solicitud.scoring.ocr.length > 0 ? '$'+$helper.moneyFormat(solicitud.scoring.ocr[0].income) : "N/A" }}</li>
        </ul>
        <hr>
        <h5>Descripción del Negocio</h5>
        <ul>
            <li><span class="subtitle">¿Es empleado?:</span> {{ solicitud.es_empleado == 1 ? 'Sí' : 'No' }}</li>
            <li><span class="subtitle">Giro de negocio:</span> {{ solicitud.gironegocio && solicitud.gironegocio.segundo_nivel ? solicitud.gironegocio.segundo_nivel : "N/A" }}</li>
            <li><span class="subtitle">Descripción detallada</span> {{ solicitud.descripcion_giro_negocio }}</li>
        </ul>
        <hr>
        <h5>Políticas de Analytic Point</h5>
        <ul>
            <li><span class="subtitle">Cumple políticas:</span> {{ solicitud.cumple_politicas == 1 ? 'Sí' : 'No' }}</li>
            <li><span class="subtitle">Descripción políticas:</span> {{ solicitud.descripcion_politicas }}</li>
            <li><span class="subtitle">Observaciones comercial:</span> {{ solicitud.observaciones_comercial }}</li>
            <li><span class="subtitle">Observaciones Analytic point:</span> {{ solicitud.observaciones_originacion }}</li>
        </ul>
        <hr>
        <h5>Lead Score</h5>
        <LeadScore :solicitud="solicitud" />
        <hr>
        <h5>Open Banking</h5>
        <table class="open_banking">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Estatus</th>
                    <th>Creación</th>
                    <th>Banco</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="solicitud.scoring && solicitud.scoring.openBanking && solicitud.scoring.openBanking.customer_credentials">
                <tr v-for="(credencial, index) in solicitud.scoring.openBanking.customer_credentials" :key="index">
                    <td>{{ credencial.username }}</td>
                    <td>{{ credencial.status }}</td>
                    <td>{{ credencial.dateCreated }}</td>
                    <td>{{ credencial.credential_bank.name}}</td>
                </tr>
                </template>
            </tbody>
        </table>
        <template v-if="$auth.can('solicitudes','alianza_soc')">
        <hr>
        <h5>SOC - SISEC</h5>
        <table class="soc_sisec">
            <thead>
                <tr>
                    <th>Folio</th>
                    <th>Estatus</th>
                    <th>Comentarios</th>
                    <th>Fecha</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="solicitud.soc">
                <tr v-for="(item,index) in solicitud.soc" :key="index">
                    <td>{{ item.response.IdFolio }}</td>
                    <td>{{ item.response.canDisburse }}</td>
                    <td>{{ item.response.resultComments }}</td>
                    <td>{{ item.response.dateRegistered }}</td>
                </tr>
                </template>
            </tbody>
        </table>
        </template>
    </div>
</template>

<script>
import LeadScore from './Cliente/LeadScore';

export default {
    components: {
        LeadScore
    }
    ,props: {
        solicitud: {
            type: Object
            ,default() {
                return {
                    scoring: {
                        buro: []
                        ,avatar: null
                        ,ocr: []
                    }
                    ,leadscore: []
                }
            }
        }
    }
    ,computed: {
        alianza() {
            return this.$auth.can('creditos','alianza_soc') ? 'SOC' : 'FRD';
        }
    }
}
</script>

<style lang="scss" scoped>
    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            background-color: rgb(235, 233, 233);
            padding: 0px;
            margin: 0px;
            border-bottom: solid 1px #fff;
            // border-bottom: solid 1px #000;

            span.subtitle {
                background-color: rgb(131, 131, 131);
                width: 240px;
                color: #fff;
                padding: 5px 10px;
                display: inline-block;
            }
        }

        li:last-child {
            border: 0px;
        }
    }

    .open_banking {
        width: 100%;

        thead {
            tr {
                background-color: #4C4C4C;
                
                th {
                    padding: 5px 10px;
                }
            }
        }
    }

    .soc_sisec {
        width: 100%;

        thead {
            tr {
                background-color: #4C4C4C;
                
                th {
                    padding: 5px 10px;
                }
            }
        }
    }
</style>